    import $ from 'jquery'; 
    window.jQuery = $; 
    
    import 'slick-carousel';
    import 'slick-carousel/slick/slick.scss';
    import 'slick-carousel/slick/slick-theme.scss';
    
    import '../scss/main.scss';
    
    $(document).ready(function () {
        document.documentElement.classList.add("js-enabled");

        $(".no-js-hidden").removeClass("no-js-hidden");
        
        $(".group").on("mouseenter", function () {
            $(this).find(".mega-menu-container, .dropdown-menu").css({
                "opacity": "1",
                "visibility": "visible",
                "pointer-events": "auto",
                "display": "flex"
            });
        });

        let menuTimeout;
    
        $(".group").on("mouseleave", function () {
            let $menu = $(this).find(".mega-menu-container, .dropdown-menu");
        
            setTimeout(() => {
                if (!$menu.filter(":hover").length) { // ✅ Correct way to check hover state
                    $menu.css({
                        "opacity": "0",
                        "visibility": "hidden",
                        "pointer-events": "none",
                        "display": "none"
                    });
                }
            }, 50);
        });
        
        $(".mega-menu-container, .dropdown-menu").on("mouseenter", function () {
            clearTimeout(menuTimeout);
        });
    
        $(".mega-menu-container, .dropdown-menu").on("mouseleave", function () {
            $(this).css({
                "opacity": "0",
                "visibility": "hidden",
                "pointer-events": "none",
                "display": "none"
            });
        });
    
        // Slick slider initializations (kept the same)
        if ($('.js-hero-slider').length) {
            $('.js-hero-slider').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 5000,
                arrows: true,
                prevArrow: $('.slick-prev'),
                nextArrow: $('.slick-next'),
            });
        }
    
        $('.js-logo-slider').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            infinite: true,
            arrows: false,
            dots: false,
            adaptiveHeight: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });

        if ($('.js-post-slider').length) {
            $('.js-post-slider').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                mobileFirst: true,
                autoplay: false,
                arrows: true,
                dots: true,
                responsive: [
                    {
                       breakpoint: 767,
                       settings: "unslick"
                    }
                 ]
            });
        }
    
        if ($('.js-review-slider').length) {
            $('.js-review-slider').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
                arrows: true,
                appendDots: $('.custom-slick-dots'),
                prevArrow: $('.custom-slick-prev'),
                nextArrow: $('.custom-slick-next'),
                adaptiveHeight: true,
                responsive: [
                    {
                       breakpoint: 767,
                       settings: {
                        adaptiveHeight: true,
                        dots: true,
                       }
                    }
                 ]
            });
        }
    
        if ($('.js-product-slider').length) {
            $('.js-product-slider').slick({
                arrows: true,
                dots: false,
                infinite: true,
                adaptiveHeight: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: $('.slick-prev'),
                nextArrow: $('.slick-next'),
            });
        }
    
        $(".js-toggle-button").on("click", function () {
            let $button = $(this);
            let dataValue = $button.data('value') || 'Read More';
            let isTextVisible = $button.siblings('.js-toggle-text').is(':visible');
    
            $button.text(isTextVisible ? dataValue : 'Read Less');
            $button.siblings('.js-toggle-text').slideToggle(200);
        });
    
        function equalizeSlideHeights() {
            let maxHeight = 0;
        
            // Select all slides
            $('.js-post-slider .p-6').each(function () {
                let thisHeight = $(this).outerHeight();
                if (thisHeight > maxHeight) {
                    maxHeight = thisHeight;
                }
            });
        
            // Apply the max height to all slides
            $('.js-post-slider .p-6').css('height', maxHeight + 'px');
        }
        
        equalizeSlideHeights();
    
        // Re-run after Slick initializes
        $('.js-post-slider').on('init', function () {
            equalizeSlideHeights();
        });
    
        // Re-run on window resize
        $(window).resize(function () {
            equalizeSlideHeights();
        });

        // Initialize Slick Slider for Tabs
        let slickSlider = $('.slick-tab-slider').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: false,
            centerMode: false,
            variableWidth: true,
            prevArrow: $('.custom-prev-tab'),
            nextArrow: $('.custom-next-tab'),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        variableWidth: false,
                    }
                }
            ]
        });

        // Function to Activate Tabs
        function activateTab(tabKey) {
            let tabItems = $('.tab-list-item');
            let tabContents = $('.tab-content-item');

            // Remove active class
            tabItems.removeClass('font-bold');
            tabContents.addClass('hidden');

            // Add active class to selected tab
            $('.tab-list-item[data-tab="' + tabKey + '"]').addClass('font-bold');
            $('.tab-content-item[data-tab="' + tabKey + '"]').removeClass('hidden');

            // Move Slick slider to the correct tab index
            let tabIndex = tabItems.index($('.tab-list-item[data-tab="' + tabKey + '"]'));
            slickSlider.slick('slickGoTo', tabIndex);
        }

        // ✅ Improved: Get Current Active Tab Based on the `font-bold` Class
        function getCurrentTabKey() {
            let activeTab = $('.tab-list-item.font-bold'); // Find the active tab
            let activeTabKey = activeTab.attr('data-tab');
            console.log("Current Tab Key:", activeTabKey); // Debugging output
            return activeTabKey;
        }

        // Click Event for Tabs
        $('.tab-list-item').click(function() {
            let tabKey = $(this).attr('data-tab');
            activateTab(tabKey);
        });

        // Sync Arrows with Tabs
        $('.custom-next-tab').click(function() {
            let currentTabKey = getCurrentTabKey();
            if (!currentTabKey) return; // Prevent errors if undefined

            let tabItems = $('.tab-list-item');
            let lastTabKey = tabItems.last().attr('data-tab');

            let nextTabKey = (parseInt(currentTabKey) + 1).toString();
            if (nextTabKey > lastTabKey) {
                nextTabKey = tabItems.first().attr('data-tab'); // Wrap to first tab
            }
            activateTab(nextTabKey);
        });

        $('.custom-prev-tab').click(function() {
            let currentTabKey = getCurrentTabKey();
            if (!currentTabKey) return; // Prevent errors if undefined

            let tabItems = $('.tab-list-item');
            let firstTabKey = tabItems.first().attr('data-tab');

            let prevTabKey = (parseInt(currentTabKey) - 1).toString();
            if (prevTabKey < firstTabKey) {
                prevTabKey = tabItems.last().attr('data-tab'); // Wrap to last tab
            }
            activateTab(prevTabKey);
        });

        // Ensure First Tab is Active on Load
        let firstTabKey = $('.tab-list-item').first().attr('data-tab');
        activateTab(firstTabKey);
    });
    